//abcdef
@font-face {
  font-family: "vazir-black";
  src: url("./vazir/Vazir-Black-FD.eot") format("eot"),
    url("./vazir/Vazir-Black-FD.woff2") format("woff2"),
    url("./vazir/Vazir-Black-FD.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "vazir-medium";
  src: url("./vazir/Vazir-Medium-FD.eot") format("eot"),
    url("./vazir/Vazir-Medium-FD.woff2") format("woff2"),
    url("./vazir/Vazir-Medium-FD.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "vazir-light";
  src: url("./vazir/Vazir-Light-FD.eot") format("eot"),
    url("./vazir/Vazir-Light-FD.woff2") format("woff2"),
    url("./vazir/Vazir-Light-FD.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "roboto-regular";
  src: url("./roboto/roboto-v20-latin-regular.eot") format("eot"),
    url("./roboto/roboto-v20-latin-regular.woff2") format("woff2"),
    url("./roboto/roboto-v20-latin-regular.woff") format("woff"),
    url("./roboto/roboto-v20-latin-regular.ttf") format("truetype");
  font-display: block;
}

// Remove increase/decrease arrows when input type is Number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Remove increase/decrease arrows when input type is Number
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#root {
  // margin: 20px;
  // width: 50%;
}
